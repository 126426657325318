<template>
  <v-layout wrap>
    <v-flex
      xs12
    >
      <material-app-card
        :color="section_color"
        icon="mdi-text-box"
        title="My Approvals"
      >
        <workflow-list
          class="approval-workflows"
          table-id="my-contract"
          type="approval"
          :color="section_color"
          @click-item="reviewWorkflow"
          @auth-fail="logoff"></workflow-list>
      </material-app-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { section_color } from '@/config'
import { mapGetters, mapActions } from 'vuex'
import WorkflowList from "@/components/app/workflow-list-v3";

export default {
  components: {
    WorkflowList
  },
  computed: {
    ...mapGetters('app', [
      'isMobile'
    ]),
    section_color() {
      return section_color['approval']
    }
  },
  methods: {
    ...mapActions('app', ['setMainTitle']),
    addContract() {
      try {
        this.$router.push({path: '/legal/contract/client-project/new'})
      } catch (err) {
        console.log('Error [add client project contract]: ')
        // console.log(err)
      }
    },
    reviewWorkflow(item) {
      try {
        if (!item || !item.item || !item.item.token || !item.item.requestType) {
          throw 'Missing Params'
        }
        
        switch (item.item.requestType) {
          case "Client Project Contract":
            this.$router.push({path: `/legal/request/details/client-project/${item.item.token}`})
            break;

          case 'Vendor Contract':
            this.$router.push({path: `/legal/request/details/vendor/${item.item.token}`})
            break;

          case 'Other Legal Requests':
            this.$router.push({path: `/legal/request/details/other/${item.item.token}`})
            break;

          case 'GfK Vendor Contract':
            this.$router.push({path: `/legal/request/details/gfk-vendor/${item.item.token}`})
            break;
        
          case "WBS Request":
            this.$router.push({path: `/ci/wbs/details/${item.item.token}`})
            break;
        
          default:
            break;
        }
      } catch (err) {
        console.log('Error [review contract]: ')
        // console.log(err)
      }
    },
    logoff() {
      this.$router.push({path: '/login'})
    }
  },
  created () {
    this.setMainTitle('Approval')
  }
}
</script>

<style lang="sass">
.approval-workflows
  margin-top: -32px

</style>
